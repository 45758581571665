header{
  position: relative;
  display:flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  height: 8rem;
  flex-shrink: 0;
}

header [class^='section-']{
  position: relative;
  display:flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  padding:0 .5rem;
}

header .section-left, header .section-right {
  flex-basis:20%;
  flex-shrink: 0;
}

header .section-left {
  justify-content: flex-start;
}

header .section-middle {
 flex-direction: column;
 justify-content: center;
}

.section-middle  h4 {
  margin-top:.6rem;
}

header .section-right {
  justify-content: flex-end;
}

header .logo{
  width:50%;
  min-width: 120px;
  display: block;
  transform: translateY(-10%)
}

header .logo-small{
  width:50%;
  min-width: 36px;
  display: none;
}

header .choose-region-select{
  color:#000;
  width: auto;
}

header .open-menu-button{
  display: flex;
  flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  width: 5rem;
  height: 3rem;
  background-color: transparent;
  border: none;
  color: #fff;
  cursor: pointer;

  font-family: inherit;
}

header .open-menu-button * {
  pointer-events: none;
}

header .open-menu-button h3 {
  text-align: right;
}

header .open-menu-button .arrow-icon{
  height: 1.4rem;
  margin-left: .3rem;
  opacity: .7;
}

.pier-bubble-menu--bottom-right{
  left:auto;
  bottom: 1.4rem;
}

@media screen and (max-width:540px) {
  header .logo {
    display: none;
  }
  header .logo-small {
    display: block;
  }
}

@media screen and (max-width:480px) {
  .pier-bubble-menu{
    min-width: 14rem;
  }
  .pier-bubble-menu__item{
    font-size: 1.2rem;
  }
  .section-middle h4 {
    display: none;
  }
}