footer{
  position: relative;
  display:flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  height: 4rem;
  width: 100%;
  
  /* bottom: 0; */
}

footer::before{
  content:"";
  position: absolute;
  top:0;
  left:50%;
  transform: translateX(-50%);
  width: 200%;
  height: 100%;
  background-color: #131e29;
}

.footer-bg {

}

footer [class^='section-']{
  position: relative;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

footer .section-left, footer .section-right {
  width:10%;
}

footer p {
  font-size: .8rem;
}

footer .coalition-copy {
  margin-bottom:.5em;
}

.coalition-copy a {
  text-decoration:underline;
}

.coalition-copy a:visited {
  text-decoration:inherit;
}

footer .section-left {
  justify-content: flex-start;
}

footer .section-right {
  justify-content: flex-end;
}

footer .main-logo {
  width:50%;
  min-width: 120px;
}


footer .cta-help{
  margin-top: -3rem;
  min-width: 5rem;
}