@font-face {
  font-family: MulishReg;
  src: url(./fonts/Mulish-Regular.woff);
  font-display: block;
}

@font-face {
  font-family: MulishLight;
  src: url(./fonts/Mulish-Light.woff);
  font-display: block;
}

@font-face {
  font-family: MulishBold;
  src: url(./fonts/Mulish-Bold.woff);
  font-display: block;
}

@font-face {
  font-family:CenturyGothic;
  src: url(./fonts/CenturyGothic.woff);
  font-display: block;
}

.App {
  width:100%;
 
}

:root {
  --contextual-green:#00CE7C;
  --creative-blue:#4AC1E0;
  --attention-yellow:#FED925;
  --manhattan-midnight:#131E29;
  --offwhite:#f8f8f8;
  
  --panel-width:16rem;
  --panel-gap:1.2rem;
  --table-width:30rem;
}

html {
  font-size: 17px;
  overflow: hidden;
}

body {
  margin: 0;
  color:var(--offwhite);
  font-family: MulishReg, CenturyGothic, Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;

  -webkit-tap-highlight-color: transparent;
}

h1 {
  font-family: MulishBold;
  font-size: 2rem;
  margin-top:.8rem;
  text-align: center;
}

h2 {
  font-family: MulishBold;
  font-size: 1.7rem;
  text-align: center;
}

h3 {
  font-family: MulishBold;
  font-size: 1.3em;
  line-height: 1.3em;
  text-align: center;
}

h4 {
  font-size: 1.1rem;
  text-align: center;
  margin-top: .2rem;
  line-height: 1.05em;
}

p{
  line-height: 1.15em;
  text-align: center;
}

strong {
  font-family: MulishBold;
}

.nowrap {
  white-space: nowrap
}

sup {
  font-size: .6em;
  vertical-align: top;
}

/* .cta-default {
  background: linear-gradient(90deg,#00ce7c,#4ac1e0);
  border:2px solid var(--contextual-green);
  padding:.4em 2.5em;
  border-radius: 2em;
  pointer-events: all;
  font-size: 1.2em;
  color:var(--offwhite);
  cursor: pointer;
  transition:background .4s;
}

.cta-default:hover {
  background: #fff;
  color:var(--contextual-green)
}

.cta-white {
  background: var(--offwhite);
  border:2px solid var(--offwhite);
  padding:.4em 2em;
  border-radius: 2em;
  font-size: 1.1em;
  color:var(--manhattan-midnight);
  cursor: pointer;
  pointer-events: all;
  transition:background .4s;

  width: 12rem;
  white-space: nowrap;
  text-align: center;

  text-decoration: none;
}

.cta-white:visited{
  text-decoration: none;
}

.cta-white:hover {
  background: transparent;
  color:var(--offwhite);
} */

.pier-button-hero {
  pointer-events: all;
}

.cta-help{
  padding:.4em 1em;
  display: none;
}

.text-button {
  font-family: MulishBold;
  color:var(--offwhite);
  pointer-events: all;
  cursor: pointer;
  background-color: transparent;
  font-size: .5rem;
  border:.05em solid var(--creative-blue);
  padding: .7rem 1.563em;
  border-radius: 1em;
  opacity: .5;
}

.gradient-1 {
  background-image:linear-gradient(120deg, #00ce7c, #4ac1e0);
}

.gradient-8 {
  background-image:linear-gradient(120deg, #131e29, #1c4f5b);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.step-number{
  width: 2.5rem;
  margin:1rem;
}

.step-number img {
  width: 100%;
  display:block
}

.view-container {
  position: relative;
  width:100%;
  max-width: 1500px;
  margin:0 auto;
  padding:0rem 2rem;
  display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: space-between;
}

main{
  position: relative;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding:1rem .5rem .5rem .5rem;
  height: 90%;
  opacity:0;
  transition: opacity .3s;
}

table{
  border-collapse: collapse;
  display: block;
  /* border-top: darkgrey solid 1px;
  border-bottom: darkgrey solid 1px; */
  border:darkgrey solid 1px;
  font-size: 1rem;
  max-width: 30rem;
  margin-top:1rem;
}

tbody{
  width: 100%;
  display: table;
}

tbody tr {
  background-color: rgba(255,255,255,.15);
  
}

tbody tr:not(:first-child) {
  border-top: darkgrey solid 1px;
}

thead tr {
  background-color: rgba(255,255,255,.2);
}

table tr:nth-child(2n){
  background-color: rgba(255,255,255,.1);
}

table td, table th{
  padding:1em;
  /* border-left:darkgrey solid 1px;
  border-right: darkgrey solid 1px; */
}

table p {
  text-align: left;
}

p.table-footnote {
  max-width: 30rem;
  font-style: italic;
}

p.table-footnote p {
  /* text-align: left; */
}

.panel {
  background-color: var(--offwhite);
  color: var(--manhattan-midnight);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border-radius: 1rem;
}

main [class^='section-']{
  position: relative;
  display:flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding:2rem .5rem 5rem .5rem;
  width: 100%;
}

main [class^='content-']{
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: auto;
  gap:var(--panel-gap);
  margin-top:3rem;
}

main [class^='content-'] .inner-content:not(:first-child){
  margin-top:var(--panel-gap)

}

/* Landing Page ---------------------------------------------------------------------- */

.hex-visual-1,.hex-visual-2 {
  position: absolute;
  top:50%;
  left:50%;
  width: 12rem;
}

.hex-visual-1{
  transform:translate(60%, -70%)
}

.hex-visual-2{
  transform:translate(-160%, 0%)
}

.choose-country-panel {
  padding:3rem;
}

.choose-country-panel > *:not(:first-child){
  margin-top:2em;
}

.choose-country-panel .cta-default {
  width:15rem;
}

.choose-country-select{
  width:15rem;
  z-index: 200;
}

/* Formats and Final Specs Page ------------------------------------------------------ */

main .content-panels {
  width: calc((var(--panel-gap) * 3) + (var(--panel-width) * 4));
}

main .content-ctas {
  margin-top: 2rem;
  flex-direction: column;
  justify-content: center;
}

main .content-ctas-inner{
  margin-top:1rem;
  justify-content: center;
}

.panel-select {
  cursor: pointer;
  padding:1rem .5rem .75rem .5rem;
  width:var(--panel-width);
  height:20rem ;
  overflow: hidden;
}

.section-templates .panel-select{
  height:18rem;
}

.panel-select > *{
  pointer-events: none;
}

.panel-select .section-version-badge {
  top:1em;
}

.section-version-badge h4 {
  font-family: MulishBold;
  color:#fff;
  font-size: 1em;
}


.panel-select [class^='section-']{
  padding:.5rem .5rem;
}

.panel-select .background {
  position: absolute;
  width: 200%;
  left:-20%;
  height: 300%;
  top:0;
  background-color: grey

}

.format-icon {
  object-fit: contain;
  width:10em;
  height: 6em;
}


.panel-select-highlight:not(:only-child):hover{
  border-radius: 1rem;
  outline:2px var(--contextual-green) solid;
}

.panel-modal {
   width: 32rem;
   padding:1rem;
}

.panel-modal [class^='section-']{
  padding:.5rem .5rem;
}

.panel-modal-close{
  font-family: MulishBold;
  position: absolute;
  border:.05em #000 solid;
  font-size: 1rem;
  padding:.7em;
  border-radius: 2em;
  background-color: transparent;
  top:.5rem;
  right:.5rem;
}

.panel-modal-close span {
  position: absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-60%)
}

main .content-specs{
  align-items: flex-start;
  justify-content: center;
}

.content-specs .single-column {
  max-width: 40rem;
}

.tier-icons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top:1em;
  width: 100%;
}

.tier-icon {
  width: 7.5%;
  margin:0 .1em;
}

.panel-select .line{
  background-color: var(--manhattan-midnight);
  /* margin:1em; */
  width:90%;
  height: 1px;
}


main > .line{
  background-color: var(--offwhite);
  width:100%;
  height: 1px;
}

main .content-help{
  flex-direction: column;
  gap: 3rem;
  margin-top:1.5rem;
}

main .content-help p {
  width: 80%;
}

.react-tooltip {
  font-family: MulishReg;
}

.react-tooltip p {
  font-size: .9rem;
}

.tiers-info-hover{
  position: absolute;
  font-family: MulishReg;
  right:0;
  top:0;
  transform: translate(120%, -60%);
  pointer-events: all;
  font-size: .7em;
  width: 1.4em;
  height: 1.4em;
  opacity: .7;
}

/* Guru Selectors -------------------------------------------------------------------- */
.ghq-card-content__paragraph:not(:last-child){
  margin-bottom: .5em;
}

/* pier-design-system */
.css-1dimb5e-singleValue {
  overflow: visible !important;
}

/* Media Queries --------------------------------------------------------------------- */

@media screen and (max-width:1000px) {
  main .content-panels {
    width: calc((var(--panel-gap) * 2) + (var(--panel-width) * 3));
  }

  :root{
    --panel-gap:2rem;
  }
}

@media screen and (max-width:800px) {
  main .content-panels {
    width: calc((var(--panel-gap) * 1) + (var(--panel-width) * 2));
  }
}



@media screen and (max-width:1300px) {
  html{
    font-size: 1.3vw;
  }
}

@media screen and (max-width:1000px) {
  html{
    font-size: 14px;
  }
}

@media screen and (max-width:480px) {
  html{
    font-size: 3.5vw;
  }
  main .content-panels {
    width: calc(var(--panel-width));
  }
  .view-container{
    padding:0 1rem;
  }
}
